import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionHeader from "../../components/2022/section-header";
import Quote from "../../components/2022/quote";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <article className="util-margin-btm-20vh" data-observer="section-6">
  <a className="cmp-back-link" id="section-6" href="#section-link-6" tabIndex="-1">Back To Top</a>
  <SectionHeader number={6} title="Looking Ahead" mdxType="SectionHeader">
    <p>This year’s survey revealed that successful design systems have established onboarding, training, and support practices for their subscribers and contributors. And the subscribers’ needs are met when they are well-supported and the design system itself is a credible source of truth.</p>
    <p>But design systems are faced with challenges like technical debt, adoption struggles, and discrepancies between design and code. These are the same cracks that subscribers see as they report being challenged by bad documentation and poor usability. It’s time for design systems to put the subscriber experience first and formalize processes so that they can be successful products for their organizations.</p>
  </SectionHeader>
  <Quote source="Design System Maintainer" mdxType="Quote">We believe in our design system. It is not perfect and not perfectly used, but we all want to make it better and can all contribute.</Quote>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      