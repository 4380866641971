import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Grid from "../../components/2022/grid";
import GridCell from "../../components/2022/grid-cell";
import TOCLink from "../../components/2022/toc-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <nav id="toc">
  <h2 className="cmp-type-body-large util-pad-top-lg util-color-gray">Contents</h2>
  <Grid gap="lg" gapRowMD="1xl" className="util-margin-vrt-10vh" mdxType="Grid">
    <GridCell span={5} spanMD={4} mdxType="GridCell">
      <TOCLink to={1} text="Respondents" mdxType="TOCLink" />
    </GridCell>
    <GridCell span={5} spanMD={4} startMD={5} startLG={7} mdxType="GridCell">
      <TOCLink to={2} text="What’s in a Design&nbsp;System" mdxType="TOCLink" />
    </GridCell>
    <GridCell span={5} spanMD={4} mdxType="GridCell">
      <TOCLink to={3} text="Subscribers’ Needs" mdxType="TOCLink" />
    </GridCell>
    <GridCell span={5} spanMD={4} startMD={5} startLG={7} mdxType="GridCell">
      <TOCLink to={4} text="Maintainers’ Challenges" mdxType="TOCLink" />
    </GridCell>
    <GridCell span={5} spanMD={4} mdxType="GridCell">
      <TOCLink to={5} text="Accepting Contributions" mdxType="TOCLink" />
    </GridCell>
    <GridCell span={5} spanMD={4} startMD={5} startLG={7} mdxType="GridCell">
      <TOCLink to={6} text="Looking Ahead" mdxType="TOCLink" />
    </GridCell>
  </Grid>
    </nav>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      