import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionHeader from "../../components/2022/section-header";
import Grid from "../../components/2022/grid";
import GridCell from "../../components/2022/grid-cell";
import Number from "../../components/2022/number";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <article className="util-margin-vrt-20vh" data-observer="section-1">
  <a className="cmp-back-link" id="section-1" href="#section-link-1" tabIndex="-1">Back To Top</a>
  <SectionHeader number={1} title="Respondents" mdxType="SectionHeader">
    <p>Of the 219 responses, 183 spent most of their time as a creator or maintainer of a design system while 37 spent most of their time as a user or subscriber. We will refer to these respondents as maintainers and subscribers from here out.</p>
   </SectionHeader>
  <Grid className="util-margin-btm-lg util-margin-btm-1xl@md util-margin-btm-2xl@lg" mdxType="Grid">
    <GridCell spanMD={8} spanLG={7} mdxType="GridCell">
      <Number size="large" title="Total Responses" number={219} mdxType="Number" />
    </GridCell>
  </Grid>
  <Grid gap="lg" gapColMD="1xl" gapColLG="none" mdxType="Grid">
    <GridCell spanSM={3} spanMD={4} spanLG={3} mdxType="GridCell">
      <Number title="Design/<wbr />User Experience" number="58%" mdxType="Number" />
    </GridCell>
    <GridCell spanSM={3} spanMD={4} spanLG={3} startSM={4} startMD={1} startLG={5} mdxType="GridCell">
      <Number title="Development" number="19%" mdxType="Number" />
    </GridCell>
    <GridCell spanSM={3} spanMD={4} spanLG={3} mdxType="GridCell">
      <Number title="Management/<wbr />Leadership" number="15%" mdxType="Number" />
    </GridCell>
    <GridCell spanSM={3} spanMD={4} spanLG={3} startSM={4} startMD={1} startLG={5} mdxType="GridCell">
      <Number title="Project/<wbr />Product Management" number="5%" mdxType="Number" />
    </GridCell>
    <GridCell spanSM={3} spanMD={4} spanLG={3} mdxType="GridCell">
      <Number title="Other" number="3%" mdxType="Number" />
    </GridCell>
    <GridCell spanMD={3} startMD={6} startLG={9} rowStartMD={-1} rowSpanMD={6} rowSpanLG={5} positionMD="sticky" topMD="10vh" positionLG="unset" mdxType="GridCell">
      <p className="util-margin-all-none cmp-type-body-default">Our survey respondents represented more than 20 industry categories including financial services, telecommunications, IT hardware and software, healthcare and pharmaceuticals, government, manufacturing, education, and retail.</p>
    </GridCell>
  </Grid>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      