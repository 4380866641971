import React from "react"

const SparkboxLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="190" height="51" fill="none" viewBox="0 0 190 51">
      <path fill="currentColor" d="M11 33.4v7h2.2l4.1-7H11Zm23.5-15a3.5 3.5 0 0 0 0-7h-.8l-4.1 7h5Zm-23.5-7v8l11.7-8H11Zm23.5 22h-1.1l-10.3 7h11.4c2 0 3.5-1.6 3.5-3.5 0-2-1.6-3.5-3.5-3.5Zm11.2-8.3L37 31a6.5 6.5 0 0 1-2.5 12.4H18.8L8.1 50.6h-.2a.4.4 0 0 1-.4-.3v-.2l4-6.7h-3A.4.4 0 0 1 8 43V31c0-.2.2-.4.4-.4h13.4c.2 0 .3.2.3.4v.2l-6.3 10.6L37 27.5H.4A.4.4 0 0 1 0 27c0-.1 0-.2.2-.3l8-5.4V8.8c0-.2 0-.3.3-.3H27L35.2 3l4.3-3h.2c.2 0 .4.1.4.3v.2l-4.7 8C38.5 9 41 11.6 41 15c0 3.5-3 6.4-6.5 6.4H25a.4.4 0 0 1-.4-.3v-.2l7-11.9L8.8 24.4h36.7c.2 0 .4.2.4.4l-.2.3Zm25.8-6.3L69 20.2c-.4-.8-1-2.6-3.2-2.6-1.2 0-2.3 1.2-2.3 2.9s.8 2.9 3 4.3c3.3 2 4.9 4 4.9 7.4 0 4-2.3 6.6-5.9 6.6-3.7 0-5.3-2.4-6.3-5.4L62 32c.7 1.6 1.3 3.5 3.1 3.5 1.6 0 2.7-1 2.7-2.9 0-1.6-.6-2.9-3.3-4.4-2.8-1.6-4.5-3.9-4.5-7.3 0-3.7 2.6-6.6 5.7-6.6 4.2 0 5.5 3.5 6 4.5Zm4.7-4.1H81c4.2 0 6.8 3 6.8 7.8 0 4.9-2.5 7.9-6.8 7.9h-1v8h-3.7V14.7ZM79.8 27h.9c2 0 3.2-1.7 3.2-4.6 0-2.7-1.2-4.3-3.3-4.3h-.8V27Zm19.8 6h-5.1l-1.1 5.4h-3.5l5.2-23.7h4l5.2 23.7h-3.6l-1-5.3Zm-4.5-3.2H99l-2-9.5-2 9.5Zm13.6-15.1h4.4c4.3 0 7 2.8 7 7.3 0 2.9-1 5-2.6 6.4l3.5 10h-3.8l-2.9-8.7-1.3.1h-.7v8.6h-3.6V14.7Zm3.6 11.8h.6c2.1 0 3.4-1.5 3.4-4.4 0-2.6-1.3-4-3.4-4h-.6v8.4Zm19 2.3-1.6 3v6.6h-3.6V14.7h3.6V26l4.7-11.4h4L133.6 25l5.1 13.4h-4l-3.5-9.6Zm12.2-14.1h5.3c3.7 0 6.1 2.4 6.1 6.1 0 2.4-1 4.4-2.7 5.3 1.9.8 3.1 2.9 3.1 5.5 0 4-2.6 6.8-6.5 6.8h-5.3V14.7Zm3.5 10.2h1.5c1.6 0 2.7-1.5 2.7-3.7 0-2-1.1-3.3-2.7-3.3H147v7Zm1.6 10.3c1.8 0 3-1.5 3-3.7 0-2-1.2-3.5-3-3.5H147v7.2h1.6Zm17.7 3.6c-4.5 0-7-4.4-7-12.3s2.5-12.2 7-12.2c4.6 0 7.1 4.3 7.1 12.2 0 8-2.5 12.3-7 12.3Zm0-3.6c2.1 0 3.3-3 3.3-8.7 0-5.6-1.2-8.6-3.3-8.6-2 0-3.3 3-3.3 8.6s1.2 8.7 3.3 8.7Zm14.7-8.8-4.6-11.8h4l3 8.5 2.7-8.5h3.8l-4.5 12 4.6 11.8h-4l-3-8.5-2.9 8.5h-3.7l4.6-12Z"/>
    </svg>
  )
}

export default SparkboxLogo
