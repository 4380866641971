import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionHeader from "../../components/2022/section-header";
import Grid from "../../components/2022/grid";
import GridCell from "../../components/2022/grid-cell";
import Number from "../../components/2022/number";
import Gauge from "../../components/2022/gauge";
import RelatedReading from "../../components/2022/related-reading";
import RelatedArticle from "../../components/2022/related-article";
import KeyFinding from "../../components/2022/key-finding";
import Quote from "../../components/2022/quote";
import BarChart from "../../components/2022/bar-chart";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <article className="util-margin-btm-20vh" data-observer="section-2">
  <a className="cmp-back-link" id="section-2" href="#section-link-2" tabIndex="-1">Back To Top</a>
  <SectionHeader number={2} title="What’s in a Design&nbsp;System" mdxType="SectionHeader">
    <p>When asked about what their design systems contain, most respondents reported that their design systems included the usual suspects—fonts and colors, components and documentation, grids and layouts, etc.</p>
  </SectionHeader>
  
  <Grid gap="lg" gapColMD="1xl" gapColLG="none" mdxType="Grid">
    <GridCell spanSM={3} spanMD={4} spanLG={3} mdxType="GridCell">
      <Number title="Typefaces, colors, and&nbsp;styles" number="98%" mdxType="Number" />
    </GridCell>
    <GridCell spanSM={3} spanMD={4} spanLG={3} startSM={4} startMD={5} startLG={5} mdxType="GridCell">
      <Number title="Components" number="95%" mdxType="Number" />
    </GridCell>
    <GridCell spanSM={3} spanMD={4} spanLG={3} startLG={9} mdxType="GridCell">
      <Number title="Component documentation" number="85%" mdxType="Number" />
    </GridCell>
    
    <GridCell spanSM={3} spanMD={4} spanLG={3} startSM={4} startMD={5} startLG={1} mdxType="GridCell">
      <Number title="Designer-ready assets" number="75%" mdxType="Number" />
    </GridCell>
    <GridCell spanSM={3} spanMD={4} spanLG={3} startSM={1} startMD={1} startLG={5} mdxType="GridCell">
      <Number title="Grid/layout systems" number="71%" mdxType="Number" />
    </GridCell>
    <GridCell spanSM={3} spanMD={4} spanLG={3} startSM={4} startMD={5} startLG={9} mdxType="GridCell">
      <Number title="Design tokens" number="69%" mdxType="Number" />
    </GridCell>
    
    <GridCell spanSM={3} spanMD={4} spanLG={3} startMD={1} startLG={1} mdxType="GridCell">
      <Number title="Developer-ready code" number="65%" mdxType="Number" />
    </GridCell>
    <GridCell spanSM={3} spanMD={4} spanLG={3} startSM={4} startMD={5} startLG={5} mdxType="GridCell">
      <Number title="Accessibility guidelines" number="57%" mdxType="Number" />
    </GridCell>
  </Grid>
  
  <p className="cmp-type-caption util-margin-top-1xl">Responses: 219 | Respondents were asked to select all that applied.</p>
  
  <Grid className="util-margin-top-1xl" mdxType="Grid">
    <GridCell spanMD={7} spanLG={9} mdxType="GridCell">
      <h3>What’s more interesting is what they <em>didn’t</em> have in their design systems.</h3>
    </GridCell>
  </Grid>
  <Grid className="util-margin-top-1xl" gap="lg" gapRowMD="2xl" gapColLG="none" mdxType="Grid">
    <GridCell spanSM={3} spanMD={2} spanLG={3} mdxType="GridCell">
      <p>Fewer than 50% of respondents reported having content guidelines, experience design principles, or release information.</p>
    </GridCell>
    <GridCell span={4} start={2} spanSM={2} startSM={1} spanMD={2} startMD={3} startLG={5} mdxType="GridCell">
      <Gauge title="Content guidelines" number={45} mdxType="Gauge" />
    </GridCell>
    <GridCell span={4} start={2} spanSM={2} startSM={3} spanMD={2} startMD={5} startLG={8} mdxType="GridCell">
      <Gauge title="Experience design principles" number={42} mdxType="Gauge" />
    </GridCell>
    <GridCell span={4} start={2} spanSM={2} startSM={5} spanMD={2} startMD={7} startLG={11} mdxType="GridCell">
      <Gauge title="Release information" number={41} mdxType="Gauge" />
    </GridCell>
    <GridCell spanSM={3} spanMD={2} spanLG={3} mdxType="GridCell">
      <p>And fewer than 35% have playgrounds to experiment with code or animation guidance.</p>
    </GridCell>
    <GridCell span={4} start={2} spanSM={2} startSM={1} spanMD={2} startMD={3} startLG={5} mdxType="GridCell">
      <Gauge title="Playground to experiment with&nbsp;code" number={34} mdxType="Gauge" />
    </GridCell>
    <GridCell span={4} start={2} spanSM={2} startSM={3} startMD={5} spanMD={2} startLG={8} mdxType="GridCell">
      <Gauge title="Animation guidelines" number={19} mdxType="Gauge" />
    </GridCell>
  </Grid>
  
  <p className="cmp-type-caption util-margin-top-1xl">Responses: 219 | Respondents were asked to select all that applied.</p>
  
  <RelatedReading mdxType="RelatedReading">
    <RelatedArticle title="The Anatomy of a Design System" description="A design system should have certain fundamental pieces in order to be successful. But what are those pieces?" image="/images/2022/anatomy-of-design-system.svg" href="https://sparkbox.com/foundry/design_system_makeup_design_system_layers_parts_of_a_design_system" mdxType="RelatedArticle" />
  </RelatedReading>
  <hr aria-hidden={true} />
  <Grid className="util-margin-top-1xl util-margin-btm-10vh" mdxType="Grid">
    <GridCell spanMD={7} spanLG={9} mdxType="GridCell">
      <p className="cmp-type-heading-4 util-margin-all-none">We were also curious about what established processes and practices were in place for our respondents’ design systems. We found that 61% of respondents had processes for subscribers to contribute to the design system. Just 44% had governance models or shared the product roadmap for the design system. And only 16% tracked metrics&mdash;which is consistent with <a href="/2021">last year’s survey results</a>.</p>
    </GridCell>
  </Grid>
  <BarChart headingLevel="h3" title="Which of the following are established for the design system you use?" dataPoints={[["Process for contributing", 61], ["Process for determining which features or functionality will be added, updated, or removed", 44], ["Roadmap of upcoming features", 44], ["Support and training for current subscribers/contributors", 37], ["New subscriber/contributor onboarding", 30], ["Metrics tracking/reporting (usage, satisfaction, etc.)", 16], ["I don't know/prefer not to say", 11], ["Other", 4]]} mdxType="BarChart" />
  <p className="cmp-type-caption util-margin-top-1xl">Responses: 134 | Respondents were asked to select all that applied.</p>
  <Quote source="Design System Maintainer" mdxType="Quote">[Our design system] has completely revolutionized how we collaborate with other teams and document and present our brand. Now, we can't imagine operating any other way.</Quote>
  <Grid gap="lg" gapColMD="1xl" gapColLG="none" mdxType="Grid">
    <GridCell spanMD={7} spanLG={9} mdxType="GridCell">
      <h3 className="cmp-type-heading-2 util-margin-top-none util-margin-btm-lg">Respondents who felt their design system was successful had established processes and practices in place.</h3>
    </GridCell>
    <GridCell span={4} start={2} spanSM={3} startSM={1} startMD={2} startLG={1} spanLG={2} mdxType="GridCell">
      <Gauge title="Onboarding new subscribers and/or contributors" number={84} mdxType="Gauge" />
    </GridCell>
    <GridCell span={4} start={2} spanSM={3} startSM={4} startMD={5} startLG={4} spanLG={2} mdxType="GridCell">
      <Gauge title="Determining what to add, update, or remove" number={78} mdxType="Gauge" />
    </GridCell>
    <GridCell span={4} start={2} spanSM={3} startSM={1} startMD={2} startLG={7} spanLG={2} mdxType="GridCell">
      <Gauge title="Contributing" number={76} mdxType="Gauge" />
    </GridCell>
    <GridCell span={4} start={2} spanSM={3} startSM={4} startMD={5} startLG={10} spanLG={2} mdxType="GridCell">
      <Gauge title="Training and support" number={76} mdxType="Gauge" />
    </GridCell>
  </Grid>
  
  <p className="cmp-type-caption util-margin-top-1xl">Responses: 189</p>
  
  <KeyFinding mdxType="KeyFinding">Only 30% of all respondents reported their design system having support, training, and new user onboarding. Yet those who reported having <em>successful</em> design systems had these practices in place 76% of the time.</KeyFinding>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      