import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Grid from "../../components/2022/grid";
import GridCell from "../../components/2022/grid-cell";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Grid className="util-margin-vrt-20vh" mdxType="Grid">
  <GridCell spanMD={7} spanLG={9} mdxType="GridCell">
    <p className="cmp-type-section-description">Every year, we are surprised by what we learn from the perspectives
      of people who work with design systems day in and day out. This
      year, we took particular interest in learning from both the people
      building and maintaining a design system and those who use or
      contribute to it in addition to their other responsibilities.</p>
      <p className="cmp-type-section-description">We shared this survey for four weeks across our social media
      network, in Slack channels, with visitors to the Sparkbox website,
      and in emails to web professionals. Over 200 web professionals
      responded with their experiences. Read on for this year's findings.</p>
  </GridCell>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      